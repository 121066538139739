import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import './index.css'
import FavIcon from '../images/psy-scan-logo-v2.jpg'
import Header from '../components/Header/Header'
import Navigation from '../components/Navigation/Navigation'
import HeaderAds from '../components/HeaderAds/Header'
import { CloseIcon } from '../components/icons'

class Layout extends Component {
    static propTypes = {
        children: PropTypes.func,
        isMobile: PropTypes.bool,
        mobileMenu: PropTypes.bool,
    }

    state = {
        isMobile: false,
        mobileMenu: false,
        isLoading: true,
        gDeviceWidth: 1440,
        scrollPosition: 0,
        showCovidAlert: false,
    }

    componentDidMount() {
        let showCovidAlert = true
        if(sessionStorage.getItem('covidAlert')) {
            showCovidAlert = false
        }
       this.setState({
           isMobile: window.innerWidth < 768,
           isLoading: false,
           showCovidAlert
       }, () => {
           window.addEventListener("resize", this.updateDeviceState)
           window.addEventListener("scroll", this.updateScrollPosition)
       })

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDeviceState)
        window.removeEventListener("scroll", this.updateScrollPosition)
    }

    handleHideCovidAlert = () => {
        this.setState({showCovidAlert: false}, () => sessionStorage.setItem('covidAlert', 'false'))
    }

    updateDeviceState = () => {
        if(window.innerWidth >= 768) {
            this.setState({ isMobile: window.innerWidth < 768, mobileMenu: false, gDeviceWidth: window.innerWidth })
        } else {
            this.setState({ isMobile: window.innerWidth < 768, gDeviceWidth: window.innerWidth })
        }
    }

    updateScrollPosition = () => this.setState({ scrollPosition: window.scrollY})

    render() {
        const { pathname } = this.props.location

        const headerStyles = {
            boxShadow: '2px 0 4px 0 rgba(184, 183, 183, 0.5)'
        }

        return (
            <div>
                <Helmet>
                    <link rel="icon" type="image/png" href={FavIcon} />
                    
                    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-124628539-1" />
                    
                    <script>
                        {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'UA-124628539-1');
                        gtag('config', 'AW-777183209');
                        gtag('config', 'AW-777183209/cKi3CKDbm50BEOm_y_IC', {
                            'phone_conversion_number': '032 721 49 33'
                          });

                        `}
                    </script>
                </Helmet>
                {this.state.showCovidAlert && <div style={{
                    width:'100%',
                    fontSize: '0.9rem',
                    fontWeight: 400,
                    letterSpacing: 0.25,
                    backgroundColor:'#1D2143',
                    position:'relative',
                    color: "#4cae4c"
                }}>
                    <div style={{maxWidth: 1170, margin: 'auto', padding:'10px 16px',  display:'flex', justifyContent: 'center'}}>
                        <div style={{paddingRight: 24}}>
                            {/* <b style={{paddingRight: 6}}>Alerte COVID-19: </b> Il n'est pas nécessaire de quitter votre domicile. Nos consultations sont disponibles via Skype. */}
                        </div>
                        <div style={{position: 'absolute', right: 16, cursor: 'pointer', top: 0, bottom: 0, display: 'flex', alignItems:'center'}} onClick={this.handleHideCovidAlert}>
                            <CloseIcon fill="#fff" width="18" height="18"/>
                        </div>
                    </div>
            </div> }
                <div style={headerStyles}>
                    { pathname === '/neuchatel' || pathname === '/neuchatel/'
                        ? <HeaderAds
                            {...this.props}
                            {...this.state}
                            toggleMobileMenu={ () => this.setState({ mobileMenu: !this.state.mobileMenu }) }
                            closeMobileMenu={ () => {
                                this.setState({ mobileMenu: false })
                            }}
                        />
                        : <Header
                            {...this.props}
                            {...this.state}
                            toggleMobileMenu={ () => this.setState({ mobileMenu: !this.state.mobileMenu }) }
                            closeMobileMenu={ () => {
                                this.setState({ mobileMenu: false })
                            }}
                        />
                    }

                    {pathname === '/neuchatel' || pathname === '/neuchatel/' || pathname === '/reservation' || pathname === '/reservation/'
                        ? null
                    : <Navigation {...this.state}/>}


                </div>
                {/* {this.props.children({...this.props, ...this.state})} */}
                {this.props.children}
            </div>
        )
    }
}


export default Layout

