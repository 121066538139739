import React from 'react'
import Hamburger from '../Navigation/Hamburger/Hamburger'

import classes from './Header.module.css'

import Logo from '../Logo/Logo'
import NavLinks from '../Navigation/NavLinks'


const Header = ({ mobileMenu, closeMobileMenu, isMobile , toggleMobileMenu, location }) => {

    let mobileNavStyles = [classes.MobileNavLinks]
    if(mobileMenu) {
        mobileNavStyles = [classes.MobileNavLinks, classes.Active].join(' ')
    }

    return (
        <div className={classes.HeaderWrapper}>
            <Logo location={location}/>
            <div className={classes.CallActionHeader}>
                <div className={classes.CallTitle}>Appelez pour prendre un rendez-vous</div>
                <a href="tel:+41327214933" style={{
                    fontSize: 24,
                }} className={classes.PhoneNumber}
                   onClick={() => {
                       if(window.gtag) {
                           window.gtag('event', 'initCall', {
                               'event_category': "Call",
                               'event_label' : 'Website'
                           });
                       }
                   }}
                >
                    <strong>032 721 49 33</strong>
                </a>
            </div>
            <div className={classes.MobileNav}>
                <div style={{position: 'relative'}}>
                    <Hamburger clicked={toggleMobileMenu} active={mobileMenu}/>
                    {!mobileMenu
                        ? <div style={{fontSize: '0.7rem', fontWeight: 500, top: 10, position: 'absolute', right: 1}}>Menu</div>
                        : null}

                </div>

                <div onClick={closeMobileMenu} className={mobileNavStyles}><NavLinks isMobile={isMobile} active={mobileMenu} /></div>
            </div>

        </div>
    )
}

export default Header
