import React from 'react'

export const OpenLinkNewWindowIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/>
    </svg>
)

export const KeyboardArrowDown = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/><path fill="none" d="M0 0h24v24H0V0z"/>
    </svg>
)

export const KeyboardArrowUp = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/><path d="M0 0h24v24H0z" fill="none"/>
    </svg>
)

export const ClockIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
        <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/><path d="M0 0h24v24H0z" fill="none"/><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
    </svg>
)

export const LocationIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className}>
        <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
        <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
)

export const ContactIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
        <path d="M0 0h24v24H0z" fill="none"/><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"/></svg>
)

export const EmailIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/><path d="M0 0h24v24H0z" fill="none"/>
    </svg>
)

export const KeyboardArrowRight = props => (

    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/><path fill="none" d="M0 0h24v24H0V0z"/>
    </svg>
)

export const CloseIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
        <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"/></svg>
)

export const ArrowBack = props => (

    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
        <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
)

export const CallIcon = props => (

<svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
<path d="M19.23 15.26l-2.54-.29c-.61-.07-1.21.14-1.64.57l-1.84 1.84c-2.83-1.44-5.15-3.75-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52c-.12-1.01-.97-1.77-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z"/></svg>
)

export const CalendarIcon = props => (
<svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
    <path d="M0 0h24v24H0V0z" fill="none"/>
<path d="M16 13h-3c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1zm0-10v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-1V3c0-.55-.45-1-1-1s-1 .45-1 1zm2 17H6c-.55 0-1-.45-1-1V9h14v10c0 .55-.45 1-1 1z"/></svg>
)

export const CheckIcon = props => (

<svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
    <path d="M0 0h24v24H0V0z" fill="none"/>
<path d="M9 16.2l-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z"/></svg>
)

export const CheckIconCircle = props => (

<svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "24"} height={props.height ? props.height : "24"} viewBox={props.viewBox ? props.viewBox : "0 0 24 24"} fill={props.fill ? props.fill : "#383838"} style={props.style} className={props.className} onClick={props.clicked}>
    <path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.88-11.71L10 14.17l-1.88-1.88c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7c.39-.39.39-1.02 0-1.41-.39-.39-1.03-.39-1.42 0z"/></svg>
)