import React from 'react'
import Link from 'gatsby-link'

import classes from './Logo.module.css'

import LogoIcon from '../../images/psy-scan-logo-v2.jpg'

export default () => {
    
    return(
        <div className={classes.LogoWrap}>
            <Link to="/" style={{display: 'flex', alignItems:'center'}}>
                <div className={classes.LogoIconWrapper} >
                    <img src={LogoIcon} alt="Psy Scan Institute Logo" style={{width: '100%'}}/>
                </div>
                <div style={{paddingLeft: 10, display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
                    <h1 className={classes.LogoText}>PSY-SCAN INSTITUTE</h1>
                    <h1 className={classes.subLogoTitle}>Psychiatrie et psychothérapie</h1>
                </div>
            </Link>
    
        </div>
    )
}
