import React from 'react'
import Link, { navigateTo } from 'gatsby-link'
import Button from '../Button/Button'

import classes from './Navigation.module.css'


export default ({ isMobile }) => {
    const activeLink = {
        color: "#c6c6c6",
        // borderBottomColor: `${isMobile ? "rgba(9, 111, 173, 0.5)" : "#c6c6c6"}` 
    }

    return (
        <ul className={classes.NavLinksWrap} style={{zIndex: 10}}>
            <li className={classes.ListLinkWrap}><Link exact activeStyle={activeLink} className={classes.NavLink} to="/">Accueil</Link></li>
            <li className={classes.ListLinkWrap}><Link exact activeStyle={activeLink} className={classes.NavLink} to="/prestations">Prestations</Link></li>
            <li className={classes.ListLinkWrap}><Link exact activeStyle={activeLink} className={classes.NavLink} to="/a-propos-de-nous">À Propos de Nous</Link></li>
            <li className={classes.ListLinkWrap}><Link exact activeStyle={activeLink} className={classes.NavLink} to="/faq">Questions Fréquentes</Link></li>
            <li className={classes.ListLinkWrap}><Link exact activeStyle={activeLink} className={classes.NavLink} to="/carrieres">Carrières</Link></li>

            <li className={classes.NavActionButton} style={{paddingLeft: 14, paddingBottom: 14}}>
                <div style={{width: '100%', height: 4, backgroundColor: '#ced4da'}} />
            </li>
            <li className={classes.NavActionButton} style={{paddingLeft: 14, paddingBottom: 14}}>
                <Button color="primary" text="Formulaire en ligne" styles={{fontSize: '1.5rem'}} clicked={() => navigateTo('/reservation')}/>
            </li>
            <li className={classes.NavActionButton} style={{paddingLeft: 14}}>
                <a href="tel:+41327214933"><Button color="secondary" text="Appelez 032 721 49 33" styles={{fontSize: '1.5rem'}}/></a>
            </li>
        </ul>
    )
}
