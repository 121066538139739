import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button/Button'
import { navigate } from 'gatsby-link'

const CallToAction = ({ type }) => {
    if(type === 'call') {
        return (
            <button>Appelez pour Réserver</button>
        )
    }
    return (
        <Button styles={{marginTop: -5}} color="primary" text="Réserver en ligne" 
        clicked={() => navigate('/reservation', {
            // pathname: '/reservation',
            // item: 'navigation',
            state: {
                from:'navigation'
            }
        })}/>

    )
}

CallToAction.propTypes = {
    type: PropTypes.string
}

export default CallToAction